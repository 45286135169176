const INFO = {
	main: {
		title: "PORTFOLIO",
		name: "EDGAR NGELESO.",
		email: "edgarngeleso@vividinnovations.co.ke",
		logo: "/assets/images/logo.jpg",
	},

	socials: {
		twitter: "https://x.com/edgarngereso",
		github: "https://github.com/edgarngeleso",
		linkedin: "https://www.linkedin.com/in/edgar-ngeleso-832927208/",
		instagram: "https://instagram.com/",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/edgar.ngeleso",
	},

	homepage: {
		title: "Full-stack web and mobile app developer, and a Physicist.",
		description:
			`I am a full stack developer with expertise in Node.js, Python, PHP, JavaScript, Reactjs, React native and Java. I have experience in building scalable, secure and reliable web 
			applications using various frameworks and technologies.`
	},

	about: {
		title: "I’m Edgar N. I live in Nairobi Kenya, where I design the future.",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to. If you're interested in any of the projects I've worked on, please feel free to check out the code and suggest any improvements or enhancements you might have in mind. Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "SWIFF DELIVERY",
			description:
				`A products delivery application where users can order a 
				product from the platform and the product delivered to them.`,
			logo: "/assets/images/swiff-delivery.png",
			linkText: "View Project",
			link: "https://play.google.com/store/apps/details?id=com.swiff_deliveries&pcampaignid=web_share",
		},

		{
			title: "SAVOWAREHOUSE",
			description:
				"A multivendor ecommerce web app.",
			logo: "/assets/images/savo_logo.jpg",
			linkText: "View Project",
			link: "https://savowarehouse.com",
		},

		{
			title: "TMS - Transpport System",
			description:
				"A transport system where users can book tickets online for the cars they intend to board.",
			logo: "/assets/images/nodejs.png",
			linkText: "View Project",
			link: "https://tms.vividinnovations.co.ke",
		},

		{
			title: "HOTEL MANAGEMENT SYSTEM",
			description:
				"A system that allows an employer to control his/her hotel business.",
			logo: "/assets/images/laravel.png",
			linkText: "View Project",
			link: "https://hotel.vividinnovations.co.ke",
		},

		{
			title: "LOAN SYSTEM",
			description:
				"A system that allows users to borrow loans and the loans remitted to their accounts(M-Pesa)",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://loans.vividinnovations.co.ke",
		},
	],
};

export default INFO;
